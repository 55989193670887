import React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { ToggleSwitchProps } from './ToggleSwitchTypes';

/**
 * Styled Switch component with custom styles applied.
 */
const IOSSwitch = styled((props: React.ComponentProps<typeof Switch>) => (
  <Switch {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.mode === 'dark' ? '#C1E899' : '#C1E899',
              opacity: 1,
              border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
          },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
  },
  '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
  },
  '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
          duration: 500,
      }),
  },
}));

/**
 * ToggleSwitch component that renders a styled switch with a label.
 *
 * @param {ToggleSwitchProps} props - The props for the component.
 * @returns {JSX.Element} The rendered ToggleSwitch component.
 */
export default function ToggleSwitch ({
  checked, 
  onChange, 
  label
}: ToggleSwitchProps) {
    return (
        <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }} checked={checked} onChange={onChange} />}
            label={label}
        />
    );
};